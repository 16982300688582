import styled from 'styled-components';
import fluid from 'util/fluid';

const NotFound = styled.div`
	display: flex;
	flex-flow: column;
    gap: ${fluid(24, 40)};
	padding: ${fluid(40, 180)} 0 ${fluid(40, 70)};
    text-align: center;
`;

const Title = styled.h1`
    font-size: ${fluid(24, 56)};
    font-weight: bold;
`;

const Message = styled.p`
	line-height: 1.6;
	font-size: ${fluid(16, 18)};
	color: gray;
`;

const styles = {
	NotFound,
	Title,
	Message,
};

export default styles;